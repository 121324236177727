import * as BaseDialog from "@radix-ui/react-dialog";
import { Dialog } from "@radix-ui/themes";
import { SaveAndResetButtons } from "./SaveAndResetButtons";
import { useAtomValue } from "jotai";
import { paramAttributesAtom } from "../../stores/params";
import { InlineButton } from "../../components/elements/InlineButton";

export function ConfigurableParamLayout<T>({
  customValue,
  baseValue,
  setValue,
  inputValue,
  setInputValue,
  children,
  name,
  displayText,
}: {
  customValue: T | undefined;
  baseValue: T;
  inputValue: T;
  setInputValue: (value: T) => void;
  setValue: (value: T | undefined) => void;
  children: React.ReactNode;
  name: string;
  displayText: string;
}) {
  const isModified = customValue !== undefined && customValue != baseValue;
  const paramAttributes = useAtomValue(paramAttributesAtom);
  const metadata = paramAttributes[name];
  const paramValue = customValue ?? baseValue;

  return (
    <Dialog.Root>
      {/* We use the primitive trigger component so as to use asChild */}
      <BaseDialog.Trigger asChild>
        <InlineButton isHighlighted={isModified}>{displayText}</InlineButton>
      </BaseDialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>
          Modifying attribute: {metadata?.title ?? name}
        </Dialog.Title>
        <div className="my-4 flex items-start gap-2">{children}</div>
        {SaveAndResetButtons({
          baseValue: baseValue,
          paramValue: paramValue,
          inputValue: inputValue,
          setInputValue: setInputValue,
          setValue: setValue,
          isModified: isModified,
        })}
      </Dialog.Content>
    </Dialog.Root>
  );
}
