import { ParamType } from "./configurableParam.interface";
import { Checkbox } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { ConfigurableParamLayout } from "./ConfigurableParamLayout";

export function ConfigurableBooleanParam({
  customValue,
  baseValue,
  setValue,
  name,
  displayOnTrue,
  displayOnFalse,
}: {
  customValue: boolean | undefined;
  baseValue: boolean;
  setValue: (value: ParamType | undefined) => void;
  name: string;
  displayOnTrue: string;
  displayOnFalse: string;
}) {
  const paramValue = customValue ?? baseValue;
  const [inputValue, setInputValue] = useState<boolean>(paramValue);

  // Ensure that `inputValue` updates whenever `paramValue` changes
  useEffect(() => setInputValue(paramValue), [paramValue]);

  const checkboxID = `checkbox-${name.replace(" ", "-").toLowerCase()}`;

  return (
    <ConfigurableParamLayout
      baseValue={baseValue}
      customValue={customValue}
      inputValue={inputValue}
      setInputValue={setInputValue}
      displayText={paramValue ? displayOnTrue : displayOnFalse}
      name={name}
      setValue={setValue}
    >
      <Checkbox
        id={checkboxID}
        checked={inputValue}
        onCheckedChange={(checked) => {
          if (checked !== "indeterminate") {
            setInputValue(checked);
          }
        }}
      />
    </ConfigurableParamLayout>
  );
}
